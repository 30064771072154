import { template as template_28cff0639e1f40d7b9eda29f34e1041d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_28cff0639e1f40d7b9eda29f34e1041d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
