import { template as template_74f7f87e02ef4ea889f604453ef2d28f } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import CategoriesSection from "./categories-section";
import CustomSections from "./custom-sections";
import TagsSection from "./tags-section";
export default class SidebarAnonymousSections extends Component {
    @service
    siteSettings;
    static{
        template_74f7f87e02ef4ea889f604453ef2d28f(`
    <div class="sidebar-sections sidebar-sections-anonymous">
      <CustomSections @collapsable={{@collapsableSections}} />
      <CategoriesSection @collapsable={{@collapsableSections}} />

      {{#if this.siteSettings.tagging_enabled}}
        <TagsSection @collapsable={{@collapsableSections}} />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
